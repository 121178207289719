import axios, { AxiosResponse, AxiosError } from 'axios';
import config from '@/config';
import {
  PublicationType,
  ScreenGroupPublicationTypeData,
  ScreenPublicationTypeData,
  ScreenCodeResponse,
  ScreenCodeConnect,
} from '@/types/api/screens';

export async function apiGetScreenGroups(): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/screen-groups`);
  return response.data;
}

export async function apiGetScreens(filters: any): Promise<any> {
  if (filters.screen_group_id && filters.screen_group_id === 'all') {
    delete filters.screen_group_id;
  }
  const response = await axios.get(`${config.apiUrl}userapi/screens`, {
    params: filters,
  });
  return response.data;
}

export async function apiGetScreensStatus(): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/screens/connection-status`);
  return response.data;
}

export async function apiGetScreenFromScreenGroup(groupId: unknown): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/screen-groups/${groupId}/screens`);
  return response.data;
}

export async function apiGetScreenGroupHeriarchy(groupId: unknown): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/screen-groups/${groupId}/hierarchy`);
  return response.data;
}

export async function apiGetScreenGroupLayouts(groupId: number): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/screen-groups/${groupId}/layouts`);
  return response.data;
}

export async function apiGetScreenLayout(screenId: unknown): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/screens/${screenId}/layout`);
  return response.data;
}

export async function apiGetScreenLayoutChildren(screenId: unknown): Promise<AxiosResponse> {
  return axios.get(
    `${config.apiUrl}userapi/screens/${screenId}/layout/children?skip_role_check=true`,
  );
}

export async function apiGetScreenGroupLayoutChildren(
  groupId: unknown,
  layoutId: unknown,
): Promise<AxiosResponse> {
  return axios.get(
    `${config.apiUrl}userapi/screen-group/${groupId}/layout/${layoutId}/children?skip_role_check=true`,
  );
}

export async function apiGetScreenOverridePermission(screenId: unknown): Promise<AxiosResponse> {
  return axios.get(`${config.apiUrl}userapi/screens/${screenId}/override-permission`);
}

export async function apiGetGroupOverridePermission(groupId: unknown): Promise<AxiosResponse> {
  return axios.get(`${config.apiUrl}userapi/screen-group/${groupId}/override-permission`);
}

export async function verifyScreenCode(code: unknown): Promise<any> {
  const response = await axios.get(`/screens/${code}/validate`);
  return response.data;
}

export async function apiAddScreen(screen: any): Promise<any> {
  const response = await axios.post(`${config.apiUrl}userapi/screens`, { ...screen });
  return response.data;
}

export async function apiGetScreen(screenId: unknown): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/screens/${screenId}`);
  return response.data;
}

export async function apiPutScreen(screenId: unknown, screenData: unknown): Promise<any> {
  const response = await axios.put(`${config.apiUrl}userapi/screens/${screenId}`, screenData);
  return response.data;
}

export async function apiAddScreenGroup(screenData: unknown): Promise<any> {
  const response = await axios.post(`${config.apiUrl}userapi/screen-groups`, screenData);
  return response.data;
}

export async function apiPutScreenGroup(groupId: unknown, screenData: unknown): Promise<any> {
  const response = await axios.put(`${config.apiUrl}userapi/screen-groups/${groupId}`, screenData);
  return response.data;
}

export async function apiDeleteScreenGroup(groupId: unknown): Promise<any> {
  const response = await axios.delete(`${config.apiUrl}userapi/screen-groups/${groupId}`);
  return response.data;
}

export async function restartScreens(
  publicationType: PublicationType,
  data: ScreenPublicationTypeData | ScreenGroupPublicationTypeData,
): Promise<any> {
  const response = await axios.post(`${config.apiUrl}userapi/screens/restart`, {
    publication_type: publicationType,
    data: data,
  });

  return response.data;
}

export async function apiDeleteScreen(screenId: unknown): Promise<any> {
  const response = await axios.delete(`${config.apiUrl}userapi/screens/${screenId}`);
  return response.data;
}

export async function rotateScreens(screens: unknown): Promise<any> {
  const response = await axios.post('/screens/rotate', { screens });
  return response.data;
}

export async function rotateScreen(screenId: unknown, orientation: string = 'portrait') {
  return axios.put(`${config.apiUrl}userapi/screens/${screenId}/orientation`, {
    orientation,
  });
}

export async function getLicenses(organisationId: unknown): Promise<any> {
  const response = await axios.get(
    `${config.apiUrl}adminapi/organisation/${organisationId}/licenses`,
  );
  return response.data;
}

export async function getUserLicenses(): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/organisation/licenses`);
  return response.data;
}

export async function apiAddScreenLayout(screenId: unknown, layoutId: unknown): Promise<any> {
  const response = await axios.put(
    `${config.apiUrl}userapi/screens/${screenId}/layout/${layoutId}`,
  );
  return response.data;
}

export async function apiGetGroupRolesAccess(groupId: unknown): Promise<any> {
  const response = await axios.get(`${config.apiUrl}userapi/screen-groups/${groupId}/roles-access`);
  return response.data;
}

export async function apiPostGroupRolesAccess(
  groupId: unknown,
  rolesAccess: unknown,
): Promise<any> {
  const response = await axios.post(
    `${config.apiUrl}userapi/screen-groups/${groupId}/roles-access`,
    {
      roles: rolesAccess,
    },
  );
  return response.data;
}

export async function apiCreateScreenItemOverride({
  screenId,
  itemId,
  newItemId,
  overrideConfig = {},
}: {
  screenId: unknown;
  itemId: unknown;
  newItemId: unknown;
  overrideConfig: object;
}): Promise<AxiosResponse> {
  return axios.post(`${config.apiUrl}userapi/layout-overrides`, {
    screen: screenId,
    original_item: itemId,
    local_item: newItemId,
    config: overrideConfig,
  });
}

export async function apiCreateScreenGroupItemOverride({
  groupId,
  itemId,
  newItemId,
  overrideConfig = {},
  layout,
}: {
  groupId: unknown;
  itemId: unknown;
  newItemId: unknown;
  overrideConfig: object;
  layout: unknown;
}): Promise<AxiosResponse> {
  return axios.post(`${config.apiUrl}userapi/layout-overrides/screen-group`, {
    screen_group: groupId,
    original_item: itemId,
    local_item: newItemId,
    config: overrideConfig,
    layout: layout,
  });
}

export async function apiScreenUpdateItemOverride({
  overrideId,
  itemId,
  newItemId,
  overrideConfig = {},
}: {
  overrideId: unknown;
  itemId: unknown;
  newItemId: unknown;
  overrideConfig: object;
}): Promise<AxiosResponse> {
  return axios.put(`${config.apiUrl}userapi/layout-overrides`, {
    override_id: overrideId,
    original_item: itemId,
    config: overrideConfig,
    local_item: newItemId,
  });
}

export async function apiScreenGroupUpdateItemOverride({
  overrideId,
  itemId,
  newItemId,
  groupId,
  overrideConfig = {},
}: {
  overrideId: unknown;
  itemId: unknown;
  newItemId: unknown;
  overrideConfig: object;
  groupId?: number;
}): Promise<AxiosResponse> {
  return axios.put(`${config.apiUrl}userapi/layout-overrides/screen-group`, {
    override_id: overrideId,
    original_item: itemId,
    config: overrideConfig,
    local_item: newItemId,
    screen_group: groupId,
  });
}

export async function apiScreenDeleteItemOverride(
  screenId: unknown,
  overrideId: unknown,
): Promise<AxiosResponse> {
  return axios.delete(
    `${config.apiUrl}userapi/layout-overrides/${screenId}?override_id=${overrideId}`,
  );
}

export async function apiScreenGroupDeleteItemOverride(
  groupId: unknown,
  overrideId: unknown,
): Promise<AxiosResponse> {
  return axios.delete(
    `${config.apiUrl}userapi/layout-overrides/screen-group/${groupId}?override_id=${overrideId}`,
  );
}

export const apiGenerateScreenCode = (screenId: number) => {
  return axios.get<ScreenCodeResponse | AxiosError>(
    `${config.apiUrl}userapi/screen/${screenId}/code`,
  );
};

export const apiConnectToScreenWithCode = (screenId: number, code: string) => {
  return axios.post<ScreenCodeConnect | AxiosError>(
    `${config.apiUrl}userapi/screen/${screenId}/code`,
    {
      code,
    },
  );
};
